import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from "../components/layout"

import { kebabCase } from 'lodash';
import {makeName} from '../components/utils.js'
import {MainList,CatList} from '../components/utils.js'

// should be exported to both tags.js and projects.js
const CollabSet = (props) => {
	return (
		<ul className={"tagList"}>
			{props.allCollab.map(collaboration => (
			<Link key={collaboration.fieldValue} to={`/collaborations/${kebabCase(collaboration.fieldValue)}/`}>
				<li>
					{makeName(collaboration.fieldValue)} ({collaboration.totalCount})
				</li>
			</Link>
			))}
		</ul>
	)
}

const CollabList = ({ data }) => {
const allCollab = data.projects.group;
const totalCount = data.projects.totalCount;
const totalTalk = data.talks.totalCount;
	return (
	 <Layout>
		<div className={"container"}>
			<MainList project={true} total={totalCount} totalTalk={totalTalk}/>
			<CatList collaboration={true}/>
			<CollabSet allCollab={allCollab} totalCount={totalCount}/>
		</div>
	</Layout>
	
		 
	);
};

export default CollabList;

export const pageQuery = graphql`
	query {
	projects: allMarkdownRemark(
    	sort: { fields: [frontmatter___date], order: DESC }
    	filter: {frontmatter: {template: {eq: "project"}}}
    ) {
      totalCount
		group(field: frontmatter___collaboration) {
				fieldValue
				totalCount
			}
		}
		talks:allMarkdownRemark(
			sort: { fields: [frontmatter___date], order: DESC }
			filter: {frontmatter: {template: {eq: "talk"}}}
		) {
			totalCount
		}
	}
	`;